import React from "react";
import codeALikeImage from "../../images/codeALikeFocus.png";

export default function EfficiencyPage() {
  return (
    <div className="grid grid-cols-2 gap-x-[25px] p-[25px]">
      <div className="text-center col-span-2">Efficiency Metrics</div>
      <div className="text-center col-span-2">
        Efficiency Overall Score {6.3}
      </div>

      <div className="text-center col-span-2">
        <img src={codeALikeImage} alt="codeALikeImage" />
      </div>
      <div></div>
      <div></div>
    </div>
  );
}
