import React from "react";
import ChronoGraph from "../../components/Chrono/index.js";
import PerformanceBarChart from "../../components/PerformanceBarChart/index.js";
import { GITHUB_DATA } from "./data";

const millisecondsInHours = 3600000;
const milissecondsInDay = 86400000;
const millisecondsInMinutes = 60000;
export default function PerformancePage() {
  const onClickViewData = () => {
    console.log(GITHUB_DATA);
  };

  const getTimeString = (milliseconds) => {
    let formattedTimeString = "";
    if (Math.floor(milliseconds / milissecondsInDay) > 0) {
      formattedTimeString = `${Math.floor(
        milliseconds / milissecondsInDay
      )} days `;
      milliseconds = milliseconds % milissecondsInDay;
    }
    if (Math.floor(milliseconds / millisecondsInHours) > 0) {
      formattedTimeString += `${Math.floor(
        milliseconds / millisecondsInHours
      )} hours `;
      milliseconds = milliseconds % millisecondsInHours;
    }
    if (Math.floor(milliseconds / millisecondsInMinutes) > 0) {
      formattedTimeString += `${Math.floor(
        milliseconds / millisecondsInMinutes
      )} hours `;
      milliseconds = milliseconds % millisecondsInMinutes;
    }
    formattedTimeString += `${Math.floor(milliseconds / 1000)} seconds`;
    return formattedTimeString;
  };

  let pullRequestArr = [];
  let prsWithNoReviewCount = 0;
  let averageTimePerCodeReview = 0;
  const calcPullRequestTimes = () => {
    let count = 0;
    console.log("hello");
    pullRequestArr = [];
    let totalTime = 0;
    const jsonObj = JSON.parse(GITHUB_DATA);
    jsonObj.data.search.edges.forEach((edge) => {
      const pullRequest = edge.node;
      const review = pullRequest.reviews;

      //console.log(new Date("2022-01-04T20:30:23Z"));

      if (review.totalCount > 0) {
        const totalPRTime =
          Date.parse(pullRequest.mergedAt) - Date.parse(pullRequest.createdAt);
        totalTime += totalPRTime;
        console.log(totalPRTime);
        const reviewNode = review.nodes;
        console.log(reviewNode);
        pullRequestArr.push({
          key: count,
          prNumber: pullRequest.number,
          "reviewer: ": reviewNode[0]?.author?.login,
          totalPRTime: totalPRTime,
          changedFiles: pullRequest.changedFiles,
          additions: pullRequest.additions,
          deletions: pullRequest.deletions,
          totalReviews: review.totalCount,
          totalCommits: pullRequest.commits.totalCount,
          lengthOfPR:
            (Date.parse(pullRequest.mergedAt) -
              Date.parse(pullRequest.createdAt)) /
            millisecondsInHours,
        });
        count++;
      } else {
        prsWithNoReviewCount++;
      }
    });
    //console.log(jsonObj[0].data);
    //console.log(obj.data);
    console.log(
      `Total time: ${totalTime} and ${
        pullRequestArr.length
      } and average time = ${totalTime / pullRequestArr.length}`
    );

    averageTimePerCodeReview = totalTime / pullRequestArr.length;

    console.log(pullRequestArr);
    console.log(prsWithNoReviewCount);
  };
  calcPullRequestTimes();
  return (
    <div className="grid grid-cols-2 gap-x-[25px] p-[25px]">
      <div className="text-center col-span-2">Performance Metrics</div>
      <div className="text-center col-span-2">
        Performance Overall Score {7.5} (view raw data in the{" "}
        <span onClick={() => onClickViewData()}>console</span>)
      </div>
      <div className="text-center col-span-2">
        <p>
          Average: Pull request Time: {getTimeString(averageTimePerCodeReview)}
        </p>
        <p>Pull Requests without Reviews: {prsWithNoReviewCount}</p>
        <div className="padding-left:25 text-center col-span-2">
          {
            <PerformanceBarChart
              xItem="lengthOfPR"
              scale="log"
              data={pullRequestArr}
            />
          }
        </div>
      </div>

      <div>
        <ChronoGraph />
      </div>
      <div></div>
    </div>
  );
}
