import React from "react";
import CollabGraph from "../../images/CollabGraph.png";
import SentimentAnalysis from "../../images/SentimentAnalysis.jpeg";
import LineBarComposed from "../../components/LineBarComposed";
import GourceVid from "../../videos/output.mp4";
export default function CollaborationPage() {
  return (
    <div className="grid grid-cols-2 gap-x-[25px] p-[25px]">
      <div className="text-center col-span-2">
        Collaboration & Communication Metrics
      </div>
      <div className="text-center col-span-2">
        Collaboration & Communication Overall Score {7.5}
      </div>
      <div className="text-center ">
        <img src={CollabGraph} alt="who you work with"></img>
      </div>
      <div>
        <div className="text-center ">
          <img src={SentimentAnalysis} alt="who you work with"></img>
        </div>
      </div>
      <div className="text-center col-span-2">
        <LineBarComposed />
      </div>
      <div></div>

      <div className="col-span-2 center">
        <video src={GourceVid} type="video/mp4" controls />
      </div>
    </div>
  );
}
