import React from "react";
import "./App.css";
import SpaceRadarChart from "./components/SpaceRadarChart";

function App() {
  return (
    <div>
      <div className="grid grid-cols-5 gap-x-[25px] pad-[25px] border-solid border-2">
        <div className="justify-center text-justify col-span-5 ">
          {/*
          <a
            href="https://wakatime.com/oauth/authorize?client_id=BLkPlkRzdpla9Sw3YdvOXtrG&response_type=token&redirect_uri=https://torc.dev/wakatime"
            alt="oauth wakatime"
          >
            Link Wakatime
          </a>*/}
          <SpaceRadarChart />
        </div>
      </div>
    </div>
  );
}

export default App;
