import React from "react";
import { Link } from "react-router-dom";
import headerLogo from "../../images/headerLogo.png";
import BrandonTsangPic from "../../images/BrandonTsang.png";
import "./header.css";

export default function Header() {
  return (
    <div>
      <div id="container">
        <div id="floated">
          <a href="/" alt="home page">
            <img src={headerLogo} alt="headerLogo" />
            <img src={BrandonTsangPic} alt="BrandonTsangPic" />
          </a>
        </div>
        <span id="right-align">
          <a
            id="username"
            href="https://platform.opentorc.com/#/profile/brandon.tsang"
          >
            brandon.tsang
          </a>
        </span>
      </div>
      <div className="grid grid-cols-5 gap-x-[25px] pad-[25px] border-solid border-2">
        <div className="text-center col-span-5  "></div>
        <div className="text-center col-span-5  ">
          5 Metrics of <span className="text-red-600">SPACE</span> Developer
          Productivity
        </div>
        <div className="border-solid border-2 text-center  h-12 text-black">
          <Link alt="Satisfaction details" to="/satisfaction">
            <span className="text-red-600">S</span>atisfaction{" "}
          </Link>
        </div>
        <div className="text-center border-solid border-2 h-12">
          <Link alt="Performance details" to="/performance">
            <span className="text-red-600">P</span>erformance
          </Link>
        </div>
        <div className=" text-center border-solid border-2 h-12">
          <Link alt="Activity details" to="/activity">
            <span className="text-red-600">A</span>ctivity
          </Link>
        </div>
        <div className="text-center border-solid border-2  h-12">
          <Link alt="Collaborationdetails" to="/collaboration">
            <span className="text-red-600">C</span>ollaboration & Communication
          </Link>
        </div>
        <div className="text-center border-solid border-2  h-12">
          <Link alt="Efficinency details" to="/efficiency">
            <span className="text-red-600">E</span>fficiency & Flow
          </Link>
        </div>
        <div className="text-center col-span-5 border-solid border-2 h-12">
          Torc's Developer Productivity Rating is {8.72}
        </div>
      </div>
    </div>
  );
}
