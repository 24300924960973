import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export default function PerformanceBarChart(props) {
  console.log(props.data);

  return (
    <BarChart
      width={500}
      height={300}
      data={props.data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="key" />
      <YAxis scale={props.scale} domain={["auto", "auto"]} />
      <Tooltip />
      <Legend />
      <Bar dataKey={props.xItem} fill="#8884d8" />
    </BarChart>
  );
}
