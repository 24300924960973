export const GITHUB_DATA = `{
  "data": {
    "search": {
      "edges": [
        {
          "node": {
            "number": 1,
            "title": "latest version",
            "createdAt": "2022-01-14T04:13:17Z",
            "mergedAt": "2022-01-14T04:13:25Z",
            "url": "https://github.com/opentorc/productivity-playground/pull/1",
            "changedFiles": 17,
            "additions": 324,
            "deletions": 105,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 0,
              "nodes": []
            },
            "commits": {
              "totalCount": 1
            }
          }
        },
        {
          "node": {
            "number": 607,
            "title": "added codesee",
            "createdAt": "2022-01-04T20:30:23Z",
            "mergedAt": "2022-01-04T20:37:14Z",
            "url": "https://github.com/opentorc/platform-id/pull/607",
            "changedFiles": 1,
            "additions": 81,
            "deletions": 0,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2022-01-04T20:37:10Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2022-01-04T20:37:10Z"
                }
              ]
            },
            "commits": {
              "totalCount": 1
            }
          }
        },
        {
          "node": {
            "number": 10,
            "title": "removed the react error about not having a key in a list",
            "createdAt": "2022-01-03T19:36:13Z",
            "mergedAt": "2022-01-03T20:04:47Z",
            "url": "https://github.com/opentorc/platform-frontend/pull/10",
            "changedFiles": 1,
            "additions": 6,
            "deletions": 1,
            "bodyText": "minor change",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2022-01-03T20:04:41Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2022-01-03T20:04:41Z"
                }
              ]
            },
            "commits": {
              "totalCount": 2
            }
          }
        },
        {
          "node": {
            "number": 566,
            "title": "Fix/capital signup",
            "createdAt": "2021-12-19T21:36:32Z",
            "mergedAt": "2021-12-19T22:28:47Z",
            "url": "https://github.com/opentorc/platform-id/pull/566",
            "changedFiles": 1,
            "additions": 3,
            "deletions": 1,
            "bodyText": "fix comparison of Signup path to be always lowercase",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-19T22:28:44Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-19T22:28:44Z"
                }
              ]
            },
            "commits": {
              "totalCount": 1
            }
          }
        },
        {
          "node": {
            "number": 559,
            "title": "fixed route to point to dashboard",
            "createdAt": "2021-12-17T15:45:16Z",
            "mergedAt": "2021-12-17T15:50:23Z",
            "url": "https://github.com/opentorc/platform-id/pull/559",
            "changedFiles": 1,
            "additions": 1,
            "deletions": 1,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-17T15:50:19Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-17T15:50:19Z"
                }
              ]
            },
            "commits": {
              "totalCount": 1
            }
          }
        },
        {
          "node": {
            "number": 558,
            "title": "Fix/moved ahref to use history signout",
            "createdAt": "2021-12-17T15:31:23Z",
            "mergedAt": "2021-12-17T15:33:39Z",
            "url": "https://github.com/opentorc/platform-id/pull/558",
            "changedFiles": 1,
            "additions": 5,
            "deletions": 3,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-17T15:33:35Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-17T15:33:35Z"
                }
              ]
            },
            "commits": {
              "totalCount": 3
            }
          }
        },
        {
          "node": {
            "number": 557,
            "title": "more language swaps",
            "createdAt": "2021-12-17T15:30:59Z",
            "mergedAt": "2021-12-17T15:33:58Z",
            "url": "https://github.com/opentorc/platform-id/pull/557",
            "changedFiles": 1,
            "additions": 3,
            "deletions": 3,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-17T15:33:52Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-17T15:33:52Z"
                }
              ]
            },
            "commits": {
              "totalCount": 1
            }
          }
        },
        {
          "node": {
            "number": 555,
            "title": "cleaned up some job details like removing san fran",
            "createdAt": "2021-12-17T15:13:03Z",
            "mergedAt": "2021-12-17T15:13:49Z",
            "url": "https://github.com/opentorc/platform-id/pull/555",
            "changedFiles": 2,
            "additions": 4,
            "deletions": 4,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-17T15:13:45Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-17T15:13:45Z"
                }
              ]
            },
            "commits": {
              "totalCount": 1
            }
          }
        },
        {
          "node": {
            "number": 1,
            "title": "adding payment rails hash",
            "createdAt": "2021-12-15T05:21:24Z",
            "mergedAt": "2022-01-04T21:33:30Z",
            "url": "https://github.com/opentorc/torc-serverless/pull/1",
            "changedFiles": 11,
            "additions": 3337,
            "deletions": 3,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 0,
              "nodes": []
            },
            "commits": {
              "totalCount": 19
            }
          }
        },
        {
          "node": {
            "number": 550,
            "title": "moved from /profile to /dashboard",
            "createdAt": "2021-12-14T20:18:02Z",
            "mergedAt": "2021-12-17T15:32:07Z",
            "url": "https://github.com/opentorc/platform-id/pull/550",
            "changedFiles": 4,
            "additions": 17,
            "deletions": 8,
            "bodyText": "moved routing logic",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-17T15:32:03Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-17T15:32:03Z"
                }
              ]
            },
            "commits": {
              "totalCount": 2
            }
          }
        },
        {
          "node": {
            "number": 547,
            "title": "added domain to the cookie, it was missing",
            "createdAt": "2021-12-14T16:47:25Z",
            "mergedAt": "2021-12-14T16:47:59Z",
            "url": "https://github.com/opentorc/platform-id/pull/547",
            "changedFiles": 1,
            "additions": 4,
            "deletions": 1,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-14T16:47:55Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-14T16:47:55Z"
                }
              ]
            },
            "commits": {
              "totalCount": 1
            }
          }
        },
        {
          "node": {
            "number": 152,
            "title": "fixed nullish issue",
            "createdAt": "2021-12-14T15:34:54Z",
            "mergedAt": "2021-12-14T15:40:01Z",
            "url": "https://github.com/opentorc/marketing-site/pull/152",
            "changedFiles": 1,
            "additions": 2,
            "deletions": 1,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-14T15:39:50Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-14T15:39:50Z"
                }
              ]
            },
            "commits": {
              "totalCount": 1
            }
          }
        },
        {
          "node": {
            "number": 541,
            "title": "Fix/undefined cookie",
            "createdAt": "2021-12-13T21:48:15Z",
            "mergedAt": "2021-12-13T21:49:24Z",
            "url": "https://github.com/opentorc/platform-id/pull/541",
            "changedFiles": 1,
            "additions": 6,
            "deletions": 2,
            "bodyText": "added nullish and fixed signout",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-13T21:48:59Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-13T21:48:59Z"
                }
              ]
            },
            "commits": {
              "totalCount": 2
            }
          }
        },
        {
          "node": {
            "number": 539,
            "title": "Tweak stats",
            "createdAt": "2021-12-13T21:26:00Z",
            "mergedAt": "2021-12-13T21:36:10Z",
            "url": "https://github.com/opentorc/platform-id/pull/539",
            "changedFiles": 28,
            "additions": 9,
            "deletions": 5,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-13T21:35:56Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-13T21:35:56Z"
                }
              ]
            },
            "commits": {
              "totalCount": 4
            }
          }
        },
        {
          "node": {
            "number": 536,
            "title": "Fix/job desc",
            "createdAt": "2021-12-13T16:49:11Z",
            "mergedAt": "2021-12-13T16:53:00Z",
            "url": "https://github.com/opentorc/platform-id/pull/536",
            "changedFiles": 1,
            "additions": 7,
            "deletions": 1,
            "bodyText": "had the wrong job description for architect",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2021-12-13T16:51:13Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2021-12-13T16:51:13Z"
                }
              ]
            },
            "commits": {
              "totalCount": 3
            }
          }
        }
      ]
    }
  }
}`;

export const OLD_DATA = `{
  "data": {
    "user": {
      "avatarUrl": "https://avatars.githubusercontent.com/u/664840?v=4",
      "isBountyHunter": false,
      "isCampusExpert": false,
      "isDeveloperProgramMember": false,
      "isFollowingViewer": false,
      "isGitHubStar": false,
      "issueComments": {
        "totalCount": 174
      },
      "contributionsCollection": {
        "totalCommitContributions": 1439,
        "totalIssueContributions": 100,
        "totalPullRequestContributions": 184,
        "totalPullRequestReviewContributions": 28,
        "totalRepositoriesWithContributedCommits": 17,
        "totalRepositoriesWithContributedIssues": 5,
        "totalRepositoriesWithContributedPullRequestReviews": 3,
        "totalRepositoriesWithContributedPullRequests": 7,
        "totalRepositoryContributions": 14
      },
      "pinnedItems": {
        "nodes": [
          {
            "name": "home-dir",
            "url": "https://github.com/dmessing/home-dir"
          }
        ]
      },
      "repositories": {
        "totalCount": 53,
        "nodes": [
          {
            "name": "PartyApp",
            "languages": {
              "nodes": []
            }
          },
          {
            "name": "datasciencecoursera",
            "languages": {
              "nodes": []
            }
          },
          {
            "name": "datasharing",
            "languages": {
              "nodes": []
            }
          },
          {
            "name": "react",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "PHP"
                },
                {
                  "name": "Ruby"
                },
                {
                  "name": "Shell"
                },
                {
                  "name": "CoffeeScript"
                },
                {
                  "name": "TypeScript"
                }
              ]
            }
          },
          {
            "name": "tc-learnswift",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "sort-app",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "botkit",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "alexa-skills-kit-java",
            "languages": {
              "nodes": [
                {
                  "name": "Java"
                }
              ]
            }
          },
          {
            "name": "alexa",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "operationcode_backend",
            "languages": {
              "nodes": [
                {
                  "name": "Ruby"
                },
                {
                  "name": "Makefile"
                },
                {
                  "name": "API Blueprint"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "Shell"
                }
              ]
            }
          },
          {
            "name": "operationcode_frontend",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "Makefile"
                },
                {
                  "name": "Shell"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "slate",
            "languages": {
              "nodes": [
                {
                  "name": "Ruby"
                },
                {
                  "name": "Shell"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "slate_node",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "Coursera_Capstone",
            "languages": {
              "nodes": [
                {
                  "name": "Jupyter Notebook"
                }
              ]
            }
          },
          {
            "name": "Moon",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "Ruby"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "chatql-react",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "aws-deepracer-workshops",
            "languages": {
              "nodes": [
                {
                  "name": "Jupyter Notebook"
                },
                {
                  "name": "Python"
                }
              ]
            }
          },
          {
            "name": "df18minihacks",
            "languages": {
              "nodes": [
                {
                  "name": "Python"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "Apex"
                }
              ]
            }
          },
          {
            "name": "java",
            "languages": {
              "nodes": [
                {
                  "name": "Dockerfile"
                },
                {
                  "name": "Java"
                }
              ]
            }
          },
          {
            "name": "ABB-TaaS",
            "languages": {
              "nodes": [
                {
                  "name": "Shell"
                },
                {
                  "name": "Batchfile"
                },
                {
                  "name": "SQLPL"
                },
                {
                  "name": "TSQL"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "TypeScript"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "C#"
                },
                {
                  "name": "Dockerfile"
                },
                {
                  "name": "Gherkin"
                },
                {
                  "name": "Java"
                }
              ]
            }
          },
          {
            "name": "lobsters",
            "languages": {
              "nodes": [
                {
                  "name": "Ruby"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "Shell"
                }
              ]
            }
          },
          {
            "name": "blog",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "my-hugo-blog",
            "languages": {
              "nodes": [
                {
                  "name": "Shell"
                }
              ]
            }
          },
          {
            "name": "hackerref",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "dmessing",
            "languages": {
              "nodes": []
            }
          },
          {
            "name": "natterstefan",
            "languages": {
              "nodes": []
            }
          },
          {
            "name": "ftw-daily",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "osrc",
            "languages": {
              "nodes": [
                {
                  "name": "Python"
                },
                {
                  "name": "Lua"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "Dockerfile"
                }
              ]
            }
          },
          {
            "name": "myosrc",
            "languages": {
              "nodes": [
                {
                  "name": "Python"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "Shell"
                }
              ]
            }
          },
          {
            "name": "json-server-heroku",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "react-native-hero",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "Objective-C"
                }
              ]
            }
          },
          {
            "name": "task-marketplace",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "Dockerfile"
                }
              ]
            }
          },
          {
            "name": "scavenger-hunt",
            "languages": {
              "nodes": [
                {
                  "name": "Shell"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "HTML"
                }
              ]
            }
          },
          {
            "name": "levi-random-math-site",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "vscode-jupyter",
            "languages": {
              "nodes": [
                {
                  "name": "Dockerfile"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "Python"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "Shell"
                },
                {
                  "name": "Jupyter Notebook"
                },
                {
                  "name": "TypeScript"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "Less"
                },
                {
                  "name": "C++"
                }
              ]
            }
          },
          {
            "name": "my-nextjs-site",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "HTML"
                }
              ]
            }
          },
          {
            "name": "alexa-quiz-skill",
            "languages": {
              "nodes": []
            }
          },
          {
            "name": "next.js-amplify-workshop",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "intro-to-heroku",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "SCSS"
                }
              ]
            }
          },
          {
            "name": "serverless-magic-links-poc",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "enterpriseready",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "amazon-cognito-passwordless-email-auth",
            "languages": {
              "nodes": [
                {
                  "name": "CSS"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "TypeScript"
                }
              ]
            }
          },
          {
            "name": "marketing-101",
            "languages": {
              "nodes": []
            }
          },
          {
            "name": "hugo-fresh",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "HTML"
                },
                {
                  "name": "Sass"
                },
                {
                  "name": "SCSS"
                }
              ]
            }
          },
          {
            "name": "polygon-ethereum-nextjs-marketplace",
            "languages": {
              "nodes": [
                {
                  "name": "Solidity"
                },
                {
                  "name": "JavaScript"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "cognito-auth",
            "languages": {
              "nodes": [
                {
                  "name": "HTML"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "JavaScript"
                }
              ]
            }
          },
          {
            "name": "front-end",
            "languages": {
              "nodes": []
            }
          },
          {
            "name": "next.js-tailwind-authentication",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "torc-account",
            "languages": {
              "nodes": []
            }
          },
          {
            "name": "marketing-landing-sanity-io",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "CSS"
                }
              ]
            }
          },
          {
            "name": "sanity-kitchen-sink",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "CSS"
                },
                {
                  "name": "Dockerfile"
                }
              ]
            }
          },
          {
            "name": "home-dir",
            "languages": {
              "nodes": [
                {
                  "name": "Shell"
                }
              ]
            }
          },
          {
            "name": "hemlane-interview",
            "languages": {
              "nodes": [
                {
                  "name": "JavaScript"
                },
                {
                  "name": "TypeScript"
                }
              ]
            }
          }
        ]
      },
      "bio": "Future of work expert, Boston Sports and competition fanatic",
      "login": "dmessing",
      "followers": {
        "totalCount": 21
      },
      "name": "David Messinger",
      "location": "CT",
      "gists": {
        "totalCount": 2
      },
      "gistComments": {
        "totalCount": 0
      }
    }
  }
}`;

export const smallJson = `{
  "data": {
    "search": {
      "edges": [
        {
          "node": {
            "number": 1,
            "title": "latest version",
            "createdAt": "2022-01-14T04:13:17Z",
            "mergedAt": "2022-01-14T04:13:25Z",
            "url": "https://github.com/opentorc/productivity-playground/pull/1",
            "changedFiles": 17,
            "additions": 324,
            "deletions": 105,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 0,
              "nodes": []
            }
          }
        },
        {
          "node": {
            "number": 607,
            "title": "added codesee",
            "createdAt": "2022-01-04T20:30:23Z",
            "mergedAt": "2022-01-04T20:37:14Z",
            "url": "https://github.com/opentorc/platform-id/pull/607",
            "changedFiles": 1,
            "additions": 81,
            "deletions": 0,
            "bodyText": "",
            "author": {
              "login": "dmessing"
            },
            "reviews": {
              "totalCount": 1,
              "nodes": [
                {
                  "bodyText": "",
                  "createdAt": "2022-01-04T20:37:10Z",
                  "author": {
                    "login": "cwdcwd"
                  },
                  "submittedAt": "2022-01-04T20:37:10Z"
                }
              ]
            }
          }
        }
      ]
    }
  }
}`;
