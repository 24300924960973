import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

const data = [
  {
    subject: "Satisfaction",
    A: 120,
    B: 110,
    fullMark: 150,
  },
  {
    subject: "Performance",
    A: 98,
    B: 130,
    fullMark: 150,
  },
  {
    subject: "Activity",
    A: 86,
    B: 130,
    fullMark: 150,
  },
  {
    subject: "Communication/Collaboration",
    A: 99,
    B: 100,
    fullMark: 150,
  },
  {
    subject: "Efficiency & Flow",
    A: 85,
    B: 90,
    fullMark: 150,
  },
];

export default function SpaceRadarChart() {
  return (
    <RadarChart outerRadius={100} width={750} height={750} data={data}>
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis />
      <Radar
        name="Mike"
        dataKey="A"
        stroke="#8884d8"
        fill="#8884d8"
        fillOpacity={0.6}
      />
    </RadarChart>
  );
}
