import { HashRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import ActivityPage from "./pages/ActivityPage";
import CollaborationPage from "./pages/CollaborationPage";
import EfficiencyPage from "./pages/EfficiencyPage";
import PerformancePage from "./pages/PerformancePage";
import SatisfactionPage from "./pages/SatisfactionPage";
import OAuthLandingPage from "./pages/OAuthLandingPage";

import Header from "./components/Header";
function Main() {
  return (
    <div>
      <HashRouter>
        <Header />
        <Routes>
          <Route element={<App />} path="/" />
          <Route element={<ActivityPage />} path="/activity" />
          <Route element={<PerformancePage />} path="/performance" />
          <Route element={<EfficiencyPage />} path="/efficiency" />
          <Route element={<CollaborationPage />} path="/collaboration" />
          <Route element={<SatisfactionPage />} path="/satisfaction" />
          <Route element={<OAuthLandingPage />} path="/wakatime" />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default Main;
