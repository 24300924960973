import "./styles.css";
import React from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
} from "recharts";

const data = [
  {
    name: "Release A",
    kLoc: 800,
    comments: 1400,
    changes: 490,
  },
  {
    name: "Release B",
    kLoc: 967,
    comments: 1506,
    changes: 590,
  },
  {
    name: "Release C",
    kLoc: 1098,
    comments: 989,
    changes: 350,
  },
  {
    name: "Release D",
    kLoc: 1200,
    comments: 1228,
    changes: 480,
  },
  {
    name: "Release E",
    kLoc: 1108,
    comments: 1100,
    changes: 460,
  },
  {
    name: "Release F",
    kLoc: 680,
    comments: 1700,
    changes: 380,
  },
];

export default function LineBarComposed() {
  return (
    <ComposedChart
      width={500}
      height={400}
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Area
        type="monotone"
        dataKey="comments"
        fill="#8884d8"
        stroke="#8884d8"
      />
      <Bar dataKey="kLoc" barSize={20} fill="#413ea0" />
      <Line type="monotone" dataKey="uv" stroke="#ff7300" />
      <Scatter dataKey="changes" fill="red" />
    </ComposedChart>
  );
}
