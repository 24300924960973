import { Chrono } from "react-chrono";

export default function ChronoGraph() {
  const items = [
    {
      title: "Development",
      cardTitle: "Average Timeline: 3.6 days",
      url: "",
    },
    {
      title: "Rework",
      cardTitle: "Average Timeline: 0.5 days",
      url: "",
    },
    {
      title: "Review",
      cardTitle: "Average Timeline: 1.9 days",
      cardDetailedText: "Warning: This is higher than the average review time",

      url: "",
    },
  ];

  return <Chrono items={items} />;
}
