import React from "react";
import SurveyFeedback from "../../components/SurveyFeedback";
import _ from "lodash";
import BrandonKeeper from "../../images/BrandonKeeper.jpg";

const surveyTalentResponses = _.times(16, (i) => {
  return {
    name: i,
    rating: Math.floor(Math.random() * (10 - 7 + 1) + 7),
  };
});

const surveyClientResponses = _.times(16, (i) => {
  return {
    name: i,
    rating: Math.floor(Math.random() * (10 - 7 + 1) + 7),
  };
});

export default function SatisfactionPage() {
  return (
    <div className="grid grid-cols-2 gap-x-[25px] p-[25px]">
      <div className="text-center col-span-2">Satisfaction Metrics</div>
      <div className="text-center col-span-2">
        Satisfaction Overall Score {7.5}
      </div>
      <div>
        <h1>NPS Score from Client</h1>
        <SurveyFeedback data={surveyClientResponses} />
      </div>
      <div>
        <h1>NPS Score from Talent</h1>
        <SurveyFeedback data={surveyTalentResponses} />
      </div>
      <div className="col-span-2 center">
        <img src={BrandonKeeper} alt="KeeperAI Badge"></img>
      </div>
      <div></div>
    </div>
  );
}
