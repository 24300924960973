import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";

function renderLineChart(surveyResponses) {
  return (
    <LineChart
      width={400}
      height={400}
      data={surveyResponses}
      margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
    >
      <Line type="monotone" dataKey="rating" stroke="#8884d8" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
    </LineChart>
  );
}

function SurveyFeedback(props) {
  return <div>{renderLineChart(props.data)}</div>;
}

export default SurveyFeedback;
