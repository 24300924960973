import React from "react";
//import WakaTimeData from "../../libs/WakaTimeData";
//import PerformanceBarChart from "../../components/PerformanceBarChart/index.js";
import { RadialBarChart, RadialBar, Legend } from "recharts";
import TeamDash from "../../images/TeamDash.png";
import ProjectView from "../../images/ProjectView.png";
import ProjectDetails from "../../images/ProjectDetails.png";

export default function ActivityPage() {
  const getData = () => {
    return [
      { name: "Code Reviews completed as Reviewer", size: 3, fill: "#8884d8" },
      { name: "Accepted Review", size: 8, fill: "#8884d8" },
      { name: "Total Coding Time (hours)", size: 118.53, fill: "#83a6ed" },
      { name: "Number of Commits", size: 24, fill: "#8dd1e1" },
      { name: "KLoC", size: 250, fill: "#82ca9d" },
    ];
  };
  const style = {
    top: 0,
    left: 350,
    lineHeight: "24px",
  };

  const activityData = getData();
  return (
    <div className="grid grid-cols-2 gap-x-[25px] p-[25px]">
      <div className="text-center col-span-2">Activity Metrics</div>
      <div className="text-center col-span-2">Activity Overall Score {7.5}</div>
      <div className="text-center col-span-2">
        {" "}
        <RadialBarChart
          width={500}
          height={300}
          cx={150}
          cy={150}
          innerRadius={20}
          outerRadius={140}
          barSize={10}
          data={activityData}
        >
          <RadialBar
            minAngle={15}
            label={{ position: "insideStart", fill: "#000" }}
            background
            clockWise
            dataKey="size"
          />
          <Legend
            iconSize={10}
            width={120}
            height={140}
            layout="vertical"
            verticalAlign="middle"
            wrapperStyle={style}
          />
        </RadialBarChart>
      </div>
      <div className="col-span-1">
        <img src={TeamDash} alt="Team dash" />
      </div>
      <div className="col-span-1">
        <img src={ProjectView} alt="Project View" />
      </div>
      <div className="col-span-2 center">
        <img src={ProjectDetails} alt="Project specific details" />
      </div>
    </div>
  );
}
